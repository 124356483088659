import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    redirect: '/index',
    children: [{
      path: '/index',
      name: '首页',
      component: () => import('@/views/home/goodsList'),
    },{
      path: '/goodsDetail',
      name: '商品详情',
      component: () => import('@/views/goodsDetail'),
    },{
      path: '/car',
      name: '提交订单',
      component: () => import('@/views/car'),
    },{
      path: '/privacy',
      name: '隐私政策',
      component: () => import('@/views/privacy'),
    }]
  },
]

const router = new VueRouter({
  // mode:'history',
  routes
})

export default router
