<template>
	<div class="home">
		<!-- 顶部导航栏 -->

		<div class="header-main">
			<div class="container">
				<div class="header-left">
					<div class="logo-container">
						<img class="logo" src="@/assets/yuangou.png" alt="" />
						<span style="margin-left: 20px; font-size: 25px; color: #fff">杭州院购科技有限公司</span>
					</div>
				</div>
				<div class="header-brands">
					<router-link to="/" tag="p" class="nav-item">全部商品</router-link>
					<router-link to="/index?type=1" tag="p" class="nav-item">医疗器械</router-link>
					<router-link to="/index?type=2" tag="p" class="nav-item">OTC药品</router-link>
				</div>
			</div>
		</div>
		<div style="width: 100%; margin: 0 auto; background: #f5f5f5">
      <img
        style="width: 1200px; height: 400px"
        src="@/assets/home.jpg"
        alt=""
      />
    </div>
		<router-view :key="$route.fullPath" />
		<footerView />
	</div>
</template>

<script>
	// import { videoPlayer } from 'vue-video-player'
	// @ is an alias to /src
	import footer from '@/components/footer.vue';
	export default {
		name: 'Home',
		components: {
			footerView: footer,
		},
	};
</script>

<style lang="scss">
	.top-navbar {
		width: 100%;
		position: relative;
		height: 40px;
		font-size: 12px;
		background-color: #333;

		.wrap {
			width: 1226px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.links {
				width: 100%;
				height: 40px;
				line-height: 40px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				.link {
					color: #b0b0b0;
					cursor: pointer;
					line-height: 40px;

					&:hover {
						color: #ffffff;
					}
				}
				.separator {
					margin: 0 0.4rem;
					color: #424242;
					line-height: 40px;
				}
			}
		}
	}

	.header-main {
		position: relative;
		height: 100px;
		background: rgba(31, 135, 232, 0.9);

		.container {
			width: 1226px;
			margin: 0 auto;
			display: flex;
			align-items: center;

			.header-left {
				width: 450px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.all-link {
					color: #333333;
					font-size: 16px;
					cursor: pointer;
					margin-right: 10px;

					&:hover {
						color: #ff6700;
					}
				}
			}

			.header-brands {
				height: 100px;
				padding-left: 10px;
				display: flex;
				align-items: center;
				color: #fff;
				.nav-item {
					margin: 0 15px;
					cursor: pointer;
					height: 100px;
					line-height: 100px;

					&:hover {
						color: blue;
					}
				}
			}

			.header-search {
				margin-left: auto;
				position: relative;

				.search-box {
					display: flex;
					align-items: center;
					border: 1px solid #e0e0e0;
					width: 296px;
					height: 50px;
					transition: all 0.2s;

					&:hover {
						border: 1px solid #b0b0b0;

						.search-btn {
							border-left: 1px solid #b0b0b0;
						}
					}

					&.active {
						border: 1px solid #ff6700;
					}

					.input-container {
						position: relative;

						.search-input {
							width: 223px;
							height: 48px;
							padding: 0 10px;
							line-height: 48px;
						}

						.hot-goodsList {
							position: absolute;
							top: 16px;
							right: 10px;
							display: flex;
							align-items: center;

							.item {
								display: block;
								max-width: 85px;
								margin-left: 5px;
								padding: 0 5px;
								font-size: 12px;
								background: #eee;
								color: #757575;
								transition: all 0.2s;
								cursor: pointer;

								&:hover {
									color: #ffffff;
									background-color: #ff6700;
								}
							}
						}
					}

					.search-btn {
						border-left: 1px solid #e0e0e0;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 52px;
						height: 50px;
						color: #616161;
						cursor: pointer;
						transition: all 0.2s;

						.icon {
							font-size: 20px;
							font-weight: bold;
						}

						&:hover {
							color: #ffffff;
							background-color: #ff6700;
							border: 1px solid #ff6700;
							margin-right: -1px;
						}

						&.active {
							border-left: 1px solid #ff6700;
						}
					}
				}

				.keyword-list {
					z-index: 6;
					position: absolute;
					left: 0;
					width: 243px;
					border: 1px solid #ff6700;
					border-top: none;
					background-color: #ffffff;

					.item {
						max-width: 220px;
						font-size: 12px;
						padding: 6px 15px;
						cursor: pointer;

						&:hover {
							background-color: #fafafa;
						}

						&.active {
							background-color: #fafafa;
						}
					}
				}
			}
		}

		.goods-panel {
			background-color: #ffffff;
			z-index: 5;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			height: 0;
			overflow: hidden;
			transition: box-shadow 0.2s, height 0.3s ease-in;

			&.active {
				height: 229px;
				border-top: 1px solid #e0e0e0;
				box-shadow: 0 3px 4px rgba(0, 0, 0, 0.18);
			}

			.goods-container {
				width: 1226px;
				margin: 0 auto;
				display: flex;
				align-items: center;

				.goods {
					text-align: center;
					cursor: pointer;
					font-size: 12px;
					padding: 35px 12px 25px;

					&:first-child {
						margin-left: 50px;
					}

					.thumb {
						width: 160px;
						height: 110px;
						margin-bottom: 15px;
					}

					.name {
						color: #333;
						line-height: 20px;
						max-width: 200px;
					}

					.price {
						color: #ff6700;
						line-height: 20px;
					}
				}

				.separator {
					height: 100px;
					border-right: 1px solid #e0e0e0;
					margin-top: -50px;
				}
			}
		}
	}

	.logo-container {
		display: flex;
		align-items: center;
		width: 350px;
		overflow: hidden;
		cursor: pointer;

		&:hover {
			.i-home {
				margin-left: 0;
			}
		}

		.logo {
			flex-shrink: 0;
			width: 55px;
			height: 55px;
		}

		.i-home {
			flex-shrink: 0;
			font-size: 25px;
			background-color: #ff6700;
			width: 55px;
			height: 55px;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: -55px;
			transition: all 0.2s;
		}
	}
</style>
