<template>
  <div>
    <!-- 下半部分 -->
    <div class="down-half-wrapper">
      <div class="wrap">
        <!-- 声明信息 -->
        <div class="site-info-wrap">
          <div class="site-info">
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002496"
                  >浙公网安备33010902002496号</a
                >
              </div>
              <div>
                <a class="link" href="https://beian.miit.gov.cn"
                  >网站备案/许可证号：浙ICP备20016805号-1</a
                >
              </div>
            </div>
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/49690ea6-0e22-4ccd-a9bc-4998c9f71d2a.jpg"
                  >营业执照</a
                >
              </div>
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/3bd62bf2-9ec1-4bfa-b631-9dc1eca12779.jpg"
                  >互联网药品信息服务资格证书（经营性） (浙)-经营性-2020-0229 </a
                >
              </div>
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/3996160a-3d39-41f8-8694-d5650de700ce.jpg"
                  >中华人民共和国增值电信业务经营许可证 浙B2-20210197</a
                >
              </div>
            </div>
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/59faafe7-e83a-48df-b25f-2ff341f9b129.jpg"
                  >医疗器械网络交易服务第三方平台备案凭证 (浙)ICP备20016805号</a
                >
              </div>
            </div>
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/759cc0d2-55d5-4bce-86fd-9dd92252addc.jpg"
                  >网络食品销售第三方平台备案凭证 浙网食A33000063</a
                >
              </div>
            </div>
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/9f96bf59-e007-4e3b-9e3e-6a883e93f3df.jpg"
                  >第二类医疗器械经营备案凭证 浙杭食药监械经营备20202879号</a
                >
              </div>
            </div>
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/e43d2ea3-feb4-477f-94b9-81e5a50f64f6.jpg"
                  >食品经营许可证 JY13301810173420</a
                >
              </div>
            </div>
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/4a609ce9-4f9e-4e62-a803-91eecf0840b3.jpg"
                  >中国国家顶级域名证书</a
                >
              </div>
            </div>
            <div class="links">
              <div>
                <a
                  class="link"
                  href="http://www.yuangousc.cn/upload//mainimg/cf4b6b50-33eb-4809-a1b2-1dfc547eceab.jpg"
                  >软件著作权登记证书</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import footerConfig from "@/assets/footer.config.js";

export default {
  data() {
    return {
      ...footerConfig,
      hiddenWechatInfo: true,
    };
  },
  methods: {
    closeDialog() {
      this.hiddenWechatInfo = true;
    },
  },
};
</script>

<style lang="scss">
a {
  cursor: pointer;
}
.up-half-wrapper {
  // background-color: #ff6700;

  .wrap {
    width: 1226px;
    margin: 0 auto;

    .service-wrap {
      padding: 27px 0;
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .service {
        width: 19.8%;
        height: 25px;
        border-left: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #616161;
        transition: color 0.2s;

        &:first-child {
          border-left: none;
        }

        &:hover {
          color: #ff6700;
        }

        .icon {
          margin-right: 6px;
          font-size: 24px;
        }

        .name {
          font-size: 16px;
        }
      }
    }

    .service-more {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .links {
        display: flex;

        .item {
          width: 160px;
          text-align: center;

          .title {
            margin: 26px auto;
            line-height: 1.25;
            color: #424242;
          }

          .link {
            margin-bottom: 10px;
            color: #757575;
            font-size: 12px;
            display: block;

            &:hover {
              color: #ff6700;
            }
          }
        }
      }

      .contact {
        width: 242px;
        height: 112px;
        border-left: 1px solid #e0e0e0;
        text-align: center;

        .telphone-number {
          margin-bottom: 5px;
          font-size: 22px;
          line-height: 1;
          color: #ff6700;
        }

        .service-range {
          margin-bottom: 16px;
          font-size: 12px;
          color: #616161;
        }

        .btn-message {
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 118px;
          height: 28px;
          border: 1px solid #ff6700;
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            background-color: #ff6700;

            .icon {
              color: #ff6700;
            }

            .name {
              color: #ff6700;
            }
          }

          .icon {
            display: inline-block;
            font-size: 16px;
            color: #ff6700;
            margin-right: 2px;
          }

          .name {
            font-size: 12px;
            color: #ff6700;
          }
        }
      }
    }
  }
}
.down-half-wrapper {
  background-color: #f5f5f5;
  .wrap {
    width: 1226px;
    margin: 0 auto;

    .site-info-wrap {
      display: flex;
      padding-top: 30px;
      padding-bottom: 30px;
      text-align: center;

      .logo {
        width: 57px;
        height: 57px;
        margin-right: 10px;
      }

      .site-info {
        width: 100%;
        .links a {
          color: #757575;
          line-height: 18px;
          font-size: 12px;
          padding: 0 5px;
          border-left: 1px solid #b0b0b0;

          &:first-child {
            padding-left: 0;
            border-left: none;
          }

          &:hover {
            color: #ff6700;
          }
        }

        .info-text {
          color: #b0b0b0;
          line-height: 18px;
          font-size: 12px;
        }

        .info-images {
          display: flex;
          margin-top: 5px;

          .link-image {
            width: 100px;
            height: 28px;
            object-fit: contain;
            margin-bottom: 5px;

            &:first-child {
              margin-left: -8px;
            }
          }
        }
      }
    }

    .slogan {
      margin: 20px 0;
      width: 100%;
      height: 21px;
      object-fit: contain;
    }
  }
}

.qrcode {
  width: 680px;
  height: 340px;
}
</style>
