export default {
	contact: {
		telphoneNumber: '400-100-5678',
		serviceDayRange: '周一至周日',
		serviceTimeRange: '8:00-18:00'
	},
	siteLinks: ['院购商城', '关于我们', 'siteLinkImages', '会员注册', '会员登录', '问题反馈', 'Select Location'
	],
	siteLinkImages: ['https://i1.mifile.cn/f/i/17/site/truste.png', 'https://s01.mifile.cn/i/v-logo-2.png',
		'https://s01.mifile.cn/i/v-logo-1.png', 'https://i8.mifile.cn/b2c-mimall-media/ba10428a4f9495ac310fd0b5e0cf8370.png'
	]
}
